.background{
    background-color: #F7F7F7;
    height: 92vh;
    display: flex;
    justify-content: center;
    align-items: center
}

.languageHeader{
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 10px;
}

.loginDiv{
    background-color: #FFFFFF;
    padding: 24px;
    border-radius: 8px;
    width: 45vh;
}
.inputField{
  height: 40px;
  font-size: 16px;
  border: 1px solid #d9d9d9;
}
.loginFormItem label{
  color: gray !important;
  font-size: 14px;
  font-weight: 400;
}

.loginButton{
     width: 100%;
     height: 40px;
}

.title{
    letter-spacing: 0.15em;
    text-shadow: 1px 1px 0px #D83731, 1px 0 0px #D83731, 0 1px 0px #D83731;
    color: #D83731 !important;
    font-weight: 100;
}
.subtitle{
    letter-spacing: 1em;
    color: #D83731 !important;
}
.languageButton1{
    text-transform: unset !important;
    font-size: 0.8rem !important;
    padding: unset !important;
    border-radius: 6px !important;
    margin-left: 0.5em !important;
    width: 70px !important;
    color: grey;
    border: none;
    box-shadow: none;
    height: 20px;
}
.languageButton2{
    text-transform: unset !important;
    font-size: 0.8rem !important;
    padding: unset !important;
    border-radius: 6px !important;
    margin-left: 0.5em !important;
    width: 70px !important;
    color: grey;
     border: none;
    box-shadow: none;
    height: 20px;
}
.activeLanguage{
    background: #377EF3 !important;
    color: white !important;
}
.inactiveLanguage{
    color: black !important;
}