.portletHeader{
  color: white;
  font-weight: bold;
  max-width: 100%; /* Adjust the max-width as needed */
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.machineReturnPortletBG{
  background: #61dafb;
}

.manifestChangePortletBG{
  background: darkred;
}
.runningManifestPortlet{
  background: darkcyan;
}