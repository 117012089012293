@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;500;600;700&display=swap');
/*.DataTable:hover {*/
  /*background: none;*/
/*}*/
.table_main
{
    padding: 25px;
    padding-top: 10px ;
}
.DataTable table
{
}
.DataTable > .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container
{
  border: none;
}

.DataTable table tbody tr td
{
    font-family: 'Exo 2', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 5px;
}
.DataTable table tbody tr:hover > .DataTable table tbody tr td
{
    background: white;
}