.menu {
  background: white !important;
  border: none !important;

}

.menuItemStyle{
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  padding: 8px;
  color: #000;
  background: #F0F0F0;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.SubmenuItemStyle{
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  color: #000;
  background: #F0F0F0;
  margin: 7px;
}
