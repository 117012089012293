.rct-dateHeader {
    background-color: white !important;
    color: black !important;
}

.rct-item {
    height: 100%; /* Ensure the event box takes up the full height */
    display: flex;
    align-items: center; /* Vertically center the text */
    justify-content: center; /* Horizontally center the text */
    white-space: nowrap; /* Prevent the text from wrapping */
    text-overflow: ellipsis; /* Add ellipsis for overflowed text */
    overflow: hidden; /* Hide overflowed text */
}

.rct-hl-even{
    background-color: rgb(240, 240, 240) !important;
    border-left: none !important;
    height: 40px !important;
}

/* Disable pointer events for all clickable elements */
.rct-header,
.rct-day-of-week,
.rct-date-header,
.rct-time-axis,
.rct-timeline,
.rct-timeline-canvas {
    pointer-events: none !important;
    user-select: none !important;
}

.rct-vl{
    border-left: 2px solid ghostwhite !important;
}
/* Override weekend colors */
.rct-day-6, /* Saturday */
.rct-day-0 { /* Sunday */
    background-color:  rgb(240, 240, 240) !important;
}

.rct-header-root{
    background: white !important;
    align-items: center !important;
    border-bottom: none !important;
}

.timeline-container {
    overflow-x: scroll; /* Allow horizontal scrolling if needed */
}
.rct-dateHeader{
    border-bottom: none !important;
    border-left: 1px solid ghostwhite !important;
}
.rct-calendar-header {
    border: none !important;
    border-bottom: none !important;
}

.react-calendar-timeline {
    border-bottom: none !important;
    border-right: none !important;
}
.rct-sidebar {
    border-bottom: none !important;
    border-right: none !important;
    height: 40px !important;
}
.rct-sidebar-row{
    background-color: rgb(240, 240, 240) !important;
    border-bottom: none !important;
    height: 40px !important;
}

.rct-outer {
   height: 40px !important;
}

